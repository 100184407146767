#ChatBotContainer {
  position: fixed;
  bottom: 20px;
  right: 10px;
}


.container {
  display: flex;
  flex-direction: column;
  margin: 20px;
  box-shadow: 0 4px 15px 5px #0000001a;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;

  background-color: white;
  border-radius: 25px;
}

#output-container {
  display: flex;
  width: 100%;
  margin-bottom: 10px;
  justify-content: space-between;
  min-height: 60vh;
}





.dnone{
  display: none;
}

.messages-container {
  width: 55%;
}

.message-item {
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  padding: 8px;
  margin-bottom: 5px;
  border: 1px solid #ddd;
}

.success-status {
  grid-row: 1;
  grid-column: 1;
  font-size: 14px;
  border-radius: 5px;
  background-color: var(--intercom-operator-message-bg);
  padding: 15px;
  border-radius: 20px 20px 20px 0px;
  margin: 10px 0px;
  width: fit-content;
  font-family: sans-serif;
  line-height: 1.6;
}

.message p {
  line-height: 1.8 !important;
}

.message {
  grid-row: 2;
  grid-column: 2;
  font-size: 15px;

  /* changes for single form input start */
  /* color: #333;
  background-color: #c5c5ff; */
  /* changes for single form input end */

  color: #fff;
  background-color: #182286;


  /* background-color: #f1f1f1; */
  /* border-radius: 5px; */
  border-radius: 20px 20px 0px 20px;
  margin-left: 40px;
  padding: 11px;
  margin-right: 5px;
  /* changes for single form input start */
  /* width: 65%; */
  /* changes for single form input end */
  font-family: sans-serif;
}

.hello-container div video,
.hello-container video {
  border: 1px solid Zz !important;
  border: 1px solid Zz !important;
}

.hello-container div video {
  max-height: 60vh;
}


.hello-container {
  text-align: right;
  font-size: 18px;
  font-weight: bold;
  color: #007bff;
  display: flex;
  justify-content: center;
}

.input-container {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 18px;
  border-top: 1px solid lightgrey;
}

input[type="text"] {
  flex: 1;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px 0 0 5px;
}

button {
  padding: 8px 12px;
  background: #90b832;
  border: none;
  border-radius: 5px 5px 5px 5px;
  color: white;
  cursor: pointer;
  width: 100px;
  /* transition: background 0.3s ease; */
}

button:hover {
  background: #76a316; /* Reverse gradient on hover */
}


button i {
  font-size: 16px;
}





/* recording send in api start */

.message-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  max-width: 100%;
  border: none;
}

.message {
  display: flex;
  flex-direction: column;
  word-wrap: break-word;
  overflow-wrap: break-word;
  max-width: 100%;
}

audio {
  margin-top: 5px;
  width: 100%;
}

.messages-container {
  max-width: 600px;
  overflow: auto;
  max-height: 60vh;
}

.input-container input {
  border: none;
  outline: none;
}

.input-container input {
  border: none;
  outline: none;
}

.input-container textarea {
  border: none;
  outline: none;
  width: 90%;
  font-family: sans-serif;
  height: 200px;
}

.input-container button {
  margin-left: 10px;
}

.recording-indicator {
  display: flex;
  align-items: center;
  position: relative;
}

.recording-line {
  width: 100%;
  height: 4px;
  background-color: red;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.timer {
  margin-left: 8px;
  font-weight: bold;
  margin-bottom: 15px;
}

.recording-indicator button {
  background-color: #f44336;
  color: #fff;
  border: none;
  padding: 5px;
  cursor: pointer;
  z-index: 1;
  margin-left: 10px;
}

.recording-indicator button i {
  margin-right: 5px;
}


/* css as per figma start */

.chatHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #7ead18;
  padding: 18px;
  border-top-right-radius: 25px;
  border-top-left-radius: 25px;
  color: white;
  flex-wrap: wrap;
}

.iconHeadChat img,
.headMinClose img {
  margin-right: 12px;
  max-height: 35px;
  border-radius: 100%;
}

.headMinClose {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}


.iconHeadChat {
  display: flex;
  align-items: center;
}

.headTextClass .miniHead {
  font-size: 14px;
  margin-bottom: 6px;
}

.headTextClass {
  font-family: Arial, Helvetica, sans-serif;
}

.iconButton,
.headMinClose img {
  cursor: pointer;
  margin-right: 10px;
  height: 22px;
}


.receiverIconText,
.senderIconText {
  display: flex;
  align-items: flex-end;
}

.senderIconText {
  justify-content: flex-end;
}

.senderIconText img,
.receiverIconText img {
  padding: 7px;
  max-height: 35px;
}

.helpfulText img {
  cursor: pointer;
}

.helpfulText .thumbsDownClass {
  margin-top: 7px;
}

.helpfulText {
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: sans-serif;
}

.hoverZoom {
  transition: transform .2s;
  margin-left: 5px;
}

.hoverZoom:hover {
  -ms-transform: scale(1.5);
  /* IE 9 */
  -webkit-transform: scale(1.5);
  /* Safari 3-8 */
  transform: scale(1.5);
}





/* css as per figma end */


.container {
  transition: transform 1s ease, opacity 1s ease;
}

.container.slide-down {
  transform: translateY(calc(100vh - 60px));
  opacity: 1;
}

.chatHead {
  transition: transform 1s ease;
  bottom: 0;
  width: 100%;
}

.container.slide-down .chatHead {
  transform: translateY(0);
}






/* Hide scrollbar for WebKit-based browsers (Chrome, Safari) */
 
/* Hide scrollbar for Firefox */
* {
  scrollbar-width: none;
  /* Firefox */
}

/* Hide scrollbar for Internet Explorer 10+ */
* {
  -ms-overflow-style: none;
  /* Internet Explorer and Edge (legacy) */
}






/* dharmesh css */


.videochartvalue {
  border-radius: 11px 11px 0px 0px;
  bottom: -59px;
  display: flex;
  justify-content: flex-end;
  transition: height 0.5s ease, width 0.5s ease;
}

.newvideochartvalue {
  border-radius: 11px 11px 0px 0px;
  bottom: 0;
  height: 0;
  position: fixed;
  bottom: 100px;
  right: 15px;
  transition: height 3s;
}




/* css for api response in chat start  */


.receiver .senderIconText .message {
  margin: 0;
  background-color: #f2f2f2;
  color: black;
  line-height: 1.6 !important;
  font-family: Arial, Helvetica, sans-serif;

  /* changes for single form input start */
  border-radius: 20px 20px 20px 0px;
  padding: 15px;
  /* changes for single form input end */
}

.receiver .senderIconText {
  flex-direction: row-reverse;
}

/* css for api response in chat end */




.chatBoxImageClass img {
  cursor: pointer;
  bottom: 25px;
  right: 40px;
  max-height: 200px;
  position: absolute;
  border-radius: 3px;
}

@keyframes Floating {
  0% {
    transform: translate(0, 0);
  }

  65% {
    transform: translate(0, 50px);
  }

  100% {
    transform: translate(0, 0);
  }
}

/* CSS for mobile view */





@media (min-width: 769px) and (max-width: 1220px) {
  .chatBoxImageClass img {
    bottom: 74px;
    max-height: 175px;
  }
}




@media (max-width: 768px) {
  #output-container {
    flex-direction: column-reverse;
    align-items: center;
  }

  .hello-container {
    padding: 5px;
    max-height: 36vh;
  }

  .messages-container {
    min-height: 32vh;
    width: 95%;
  }

  .container {
    min-height: 66vh !important;
  }

  .headMinClose {
    width: 100%;
  }


  .chatBoxImageClass img {
    bottom: 74px;
    max-height: 175px;
  }

}

@media (min-width: 446px) and (max-width: 768px) {

  .hello-container div video,
  .hello-container video, .hello-container img {
    max-height: 25vh;
  }

  .chatBoxImageClass img {
    bottom: 74px;
    max-height: 175px;
  }

}

@media (max-width: 400px) {
  .container {
    min-height: 71vh !important;
  }

  .chatBoxImageClass img {
    bottom: 74px;
    max-height: 175px;
  }
}



/* css for chat loading start */


.chat-bubble {
  padding: 16px 28px;
  -webkit-border-radius: 20px;
  -webkit-border-bottom-left-radius: 2px;
  -moz-border-radius: 20px;
  -moz-border-radius-bottomleft: 2px;
  border-radius: 20px;
  border-bottom-left-radius: 2px;
  display: inline-block;
}

.typing {
  align-items: center;
  display: flex;
  height: 17px;
}

.typing .dot {
  animation: mercuryTypingAnimation 1.8s infinite ease-in-out;
  background-color: #6CAD96;
  border-radius: 50%;
  height: 7px;
  margin-right: 4px;
  vertical-align: middle;
  width: 7px;
  display: inline-block;
}

.typing .dot:nth-child(1) {
  animation-delay: 200ms;
}

.typing .dot:nth-child(2) {
  animation-delay: 300ms;
}

.typing .dot:nth-child(3) {
  animation-delay: 400ms;
}

.typing .dot:last-child {
  margin-right: 0;
}

@keyframes mercuryTypingAnimation {
  0% {
    transform: translateY(0px);
    background-color: #6CAD96;
  }

  28% {
    transform: translateY(-7px);
    background-color: #9ECAB9;
  }

  44% {
    transform: translateY(0px);
    background-color: #B5D9CB;
  }
}



/* css for chat loading end */











.chat-bubble-audio {
  position: relative;
  padding: 5px;
  border-radius: 10px;
  max-width: 300px;
}


.chat-bubble {
  position: relative;
  padding: 5px;
  background-color: #f1f0f0;
  border-radius: 10px;
  max-width: 300px;
}

.typing {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dot {
  width: 8px;
  height: 8px;
  margin: 0 2px;
  background-color: gray;
  border-radius: 50%;
  animation: typing 1s infinite ease-in-out;
}

@keyframes typing {

  0%,
  100% {
    opacity: 0.2;
    transform: translateY(0);
  }

  50% {
    opacity: 1;
    transform: translateY(-5px);
  }
}



/* css for loader before video start  */

.loaderVideo {
  border: 6px solid #f3f3f3;
  border-radius: 50%;
  border-top: 6px solid #7ead18;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


/* css for loader before video end  */


.loaderVideoOutside {
  height: 50vh;
  background-color: content-box;
  display: flex;
  align-items: center;
}



/* ********************************************** */

.videochartvalue {
  bottom: -25px;
}


.slideChatBox {
  position: relative;
  opacity: 0;
  animation: slide-up 2s ease-in-out forwards;
}

@keyframes slide-up {
  0% {
    transform: translateY(800px);
    opacity: 1;
  }


  100% {
    transform: translateY(100px);
    opacity: 1;
  }
}





/* ********************************** */


/* language css start */


.languagesDiv .arrow .downArrowClass {
  margin-top: 4px;
  height: 8px;
  margin-right: 0;
}

.languagesDiv .languageDropdown .searchLanguageInput {

  outline: none;
  border: none;
}


.languagesDiv {
  position: relative;
  cursor: pointer;
  padding: 8px;
  border: 1px solid #fff;
  border-radius: 4px;
  display: inline-block;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 110px;
  font-family: Arial, Helvetica, sans-serif;
  margin-right: 10px;
  margin-top: 6px;
}

.arrow {
  margin-left: 10px;
  transition: transform 0.3s ease;
}

.arrow.open {
  transform: rotate(180deg);
}

.languageDropdown {
  list-style-type: none;
  padding: 0;
  margin: 0;
  background-color: white;
  color: black;
  border: 1px solid #ccc;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  width: 100%;
  max-height: 150px;
  overflow: scroll;
  overflow-x: hidden;
}

.languageDropdown li {
  padding: 8px 12px;
  cursor: pointer;
}

.languageDropdown li:hover {
  background-color: #f0f0f0;
}




/* language css end */






/* css for mobile height  */


body,
html {
  height: 100vh;
}

@media (max-width: 576px) {
  .container {
    display: flex;
    justify-content: space-between;
    margin: 0;
  }


  .headMinClose {
    width: 100%;
  }


  .chatBoxImageClass img {
    bottom: 74px;
    max-height: 175px;
  }
}

@media (max-width: 768px) {
  .container {
    margin: 0;
    display: flex;
    justify-content: space-between;
  }

  .headMinClose {
    margin-top: 5px;
    width: 100%;
  }

  .chatBoxImageClass img {
    bottom: 74px;
    max-height: 175px;
  }
}

@media (max-width: 992px) {
  .container {
    border-radius: 0;
    margin: 0;
    display: flex;
    justify-content: space-between;
  }

  .chatHead {
    border-radius: 0;
  }

  .headMinClose {
    width: 100%;
  }


  .chatBoxImageClass img {
    bottom: 74px;
    max-height: 175px;
  }
}

@media (max-width: 1200px) {
  .container {
    margin: 0;
    display: flex;
    justify-content: space-between;
  }

  .chatBoxImageClass img {
    bottom: 74px;
    max-height: 175px;
  }
}



.avatarNameImgClass {
  display: flex;
  align-items: center;
}

.speakerIconButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 65px;
  margin-left: 20px;
}

.speakerIconButton img {
  margin-right: 5px;
  margin-top: 5px;
}

.languagesDivBoth {
  display: flex;
  align-items: center;
}



.recording-indicator img {
  height: 18px;
}


.errorPageMainClassOutside {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.errorPageMainClass {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: sans-serif;
}


.errorHead {
  font-size: 90px;
  font-weight: bold;
  color: red;
}

.errorOopsPara span {

  font-size: 50px;
}

.errorOopsPara {
  font-size: 30px;
}

.goBackButton {
  color: white;
  background-color: #acaced;
  height: 35px;
  width: 150px;
  padding: 9px;
  border-radius: 8px;
  margin-top: 20px;
}



.languageSearchDropdown {
  margin-top: 35px;
}


.chat-container {
  max-height: 500px;
  overflow-y: auto;
}




/* user bot css starts  */

.response-options button {
  color: #7ead18;
  cursor: pointer;
  background-color: transparent;
  border: 1px solid #7ead18;
  margin: 5px;
}

.response-options button:hover {
  background-color: #7ead18;
  color: white;
  transition: 0.3s;
}

.current-question {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
}

.chat-history div .botParaClass {
  background-color: #f2f2f2;
  border-radius: 5px;
  grid-area: 1 / 1;
  width: fit-content;
  margin: 10px 0;
  padding: 15px;
  width: 65%;
  line-height: 1.3;
}


.botMsgClass img,
.currentBotMsgClass img,
.userMsgClass img {
  margin: 0px 8px;
}

.currentBotMsgClass img,
.botMsgClass img {
  max-height: 25px;
}

.botMsgClass {
  display: flex;
  align-items: center;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
}

/* .response-options {
  margin-left: 40px;
} */

.userMsgClass .userParaClass {
  display: flex;
  align-items: center;
  /* background-color: #c5c5ff; */

  color: #fff;
  background-color: #182286;


  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  width: 60%;

}

.userMsgClass {
  align-items: center;
  display: flex;
  justify-content: end;
  width: 85%;
  margin-left: 25px;
}

.userParaClass {
  border-radius: 5px;
  grid-area: 1 / 1;
  width: fit-content;
  padding: 13px;
}

.currentBotMsgClass {
  display: flex;
  align-items: center;
}

.currentBotParaClass {
  background-color: #f2f2f2;
  border-radius: 5px;
  grid-area: 1 / 1;
  width: 65%;
  margin: 10px 0;
  padding: 13px;
  line-height: 1.3;
}

.chatMainClass {
  height: 60vh;
  overflow: scroll;
  width: 80%;
}

.staticVideoClass {
  text-align: center;
}

.contactFormError {
  color: red;
  font-size: 13px;
  /* changes for single form input start */
  margin: 0px 0px 3px 8px;
  /* changes for single form input end*/
}

.formInputError {
  display: flex;
  flex-direction: column;
}

.formInputNamePhone {
  max-width: 220px;
  border-radius: 3px;
  margin-top: 4px;
  /* border: none; */
  border: 1px solid #7ead18;
  outline: none;
}

.formInputError .formInputEmail {
  /* min-width: 212px; */
  max-width: 220px;
  border-radius: 6px;
  margin-top: 4px;
  margin-bottom: 5px;
  border: 1px solid #7ead18;
  /* border: none; */
  outline: none;
  padding: 9px;
}

/* .message p form button:hover{
  background-color: #7ead18;
  color: white;
} */

.message p form button {
  border: 1px solid #7ead18;
  background-color: white;
  color: #7ead18;
  border-radius: 6px;
  outline: none;
}

@media (max-width: 768px) {
  .chatMainClass {
    height: 53vh;
  }

  .hello-container {
    width: 40%;
  }
}

.inputErrorClass {
  color: red;
  margin-left: 7px;
}


.chatBoxImageClass img {
  bottom: 74px;
  max-height: 175px;
  border-radius: 100%;
}

/* user bot css ends */

@media only screen and (width: 275px) and (height: 374px) and (-webkit-device-pixel-ratio: 2) {
  #ChatBotContainer {
    width: 90%;
    height: 90vh;
  }

  #output-container {
    min-height: 70vh;
    margin-bottom: 3px;
  }

  .messages-container {
    max-height: 45vh;
  }


  .chatBoxImageClass img {
    bottom: 74px;
    max-height: 175px;
  }
}


@media only screen and (width: 375px) and (height: 667px) and (-webkit-device-pixel-ratio: 2) {
  #ChatBotContainer {
    width: 90%;
    height: 90vh;
  }

  #output-container {
    min-height: 70vh;
    margin-bottom: 3px;
  }

  .messages-container {
    max-height: 45vh;
  }
}


@media screen and (min-width: 200px) and (max-width: 500px) {
  .headMinClose {
    width: 100%;
  }
}


@media screen and (min-width: 200px) and (max-width: 375px) {

  .headMinClose {
    width: 100%;
  }
}

@media screen and (min-width: 200px) and (max-width: 600px) {
  #ChatBotContainer {
    bottom: 0;
    right: 0;
    width: 100%;
    height: 94vh;
  }

  .chatMainClass {
    width: 98%;
  }

  .input-container input[type="text"] {
    padding: 16px;
  }

  .userMsgClass {
    width: 92%;
  }


}

@media screen and (min-width: 301px) and (max-width: 600px) {
  .hello-container div video {
    max-height: 26vh;
  }

  .chatHead {
    padding: 25px 8px;
  }

  .languagesDiv {
    min-width: 90px;
    font-size: 0.75rem;
  }

  .response-options {
    font-size: 0.75rem;
  }

  #output-container {
    min-height: 70vh;
    margin-bottom: 3px;
  }

  .messages-container {
    max-height: 40vh;
  }

  .speakerIconButton {
    min-width: 10px;
    margin-left: 4px;
  }

  .input-container {
    padding: initial;
  }
}

@media screen and (min-width: 601px) and (max-width: 767px) {
  #ChatBotContainer {
    bottom: 0;
    right: 0;
    width: 100%;
    height: 90vh;
  }

  .chatMainClass {
    width: 95%;
  }

  .userMsgClass {
    width: 93%;
  }

  .headMinClose {
    width: initial;
  }

  #output-container {
    min-height: 70vh;
    margin-bottom: 3px;
  }

  .messages-container {
    max-height: 45vh;
  }
}

@media screen and (min-width: 768px) and (max-width: 900px) {
  #ChatBotContainer {
    width: 75%;
  }

  .headMinClose {
    width: initial;
  }

  #output-container {
    max-height: 70vh;
  }
}

@media screen and (min-width: 901px) and (max-width: 1000px) {
  #ChatBotContainer {
    width: 80%;
  }

  .hello-container div video,
  .hello-container video, .hello-container img {
    max-height: 61vh;
  }
}

@media screen and (min-width: 1001px) and (max-width: 1100px) {
  #ChatBotContainer {
    width: 80%;
  }

  .hello-container div video,
  .hello-container video, .hello-container img {
    max-height: 61vh;
  }
}

@media screen and (min-width: 1101px) and (max-width: 1200px) {
  #ChatBotContainer {
    width: 70%;
  }

  .hello-container div video,
  .hello-container video, .hello-container img {
    max-height: 61vh;
  }
}


@media screen and (min-width: 1201px) and (max-width: 1300px) {
  #ChatBotContainer {
    width: 70%;
  }

  .hello-container div video,
  .hello-container video, .hello-container img {
    max-height: 61vh;
  }
}

@media screen and (min-width: 1301px) and (max-width: 1400px) {
  #ChatBotContainer {
    width: 47%;
  }

  .hello-container div video,
  .hello-container video, .hello-container img {
    max-height: 61vh;
  }
}

@media screen and (min-width: 1401px) and (max-width: 1500px) {
  #ChatBotContainer {
    width: 50%;
  }

  .hello-container div video,
  .hello-container video, .hello-container img {
    max-height: 61vh;
  }
}

@media screen and (min-width: 1501px) and (max-width: 1600px) {
  #ChatBotContainer {
    width: 50%;
  }

  .hello-container div video,
  .hello-container video, .hello-container img {
    max-height: 61vh;
  }
}

@media screen and (min-width: 1601px) and (max-width: 1700px) {
  #ChatBotContainer {
    width: 45%;
  }

  .hello-container div video,
  .hello-container video, .hello-container img {
    max-height: 61vh;
  }
}

@media screen and (min-width: 1701px) and (max-width: 1800px) {
  #ChatBotContainer {
    width: 45%;
  }

  .hello-container div video,
  .hello-container video, .hello-container img {
    max-height: 61vh;
  }
}

@media screen and (min-width: 1801px) and (max-width: 1900px) {
  #ChatBotContainer {
    width: 40%;
  }

  .hello-container div video,
  .hello-container video, .hello-container img {
    max-height: 61vh;
  }
}

@media screen and (min-width: 1901px) and (max-width: 2000px) {
  #ChatBotContainer {
    width: 40%;

  }

  .hello-container div video,
  .hello-container video, .hello-container img {
    max-height: 61vh;
  }
}

@media screen and (min-width: 2001px) and (max-width: 2100px) {
  #ChatBotContainer {
    width: 40%;
  }
}

@media screen and (min-width: 2101px) and (max-width: 2200px) {
  #ChatBotContainer {
    width: 35%;
  }
}

@media screen and (min-width: 2201px) and (max-width: 2300px) {
  #ChatBotContainer {
    width: 35%;
  }
}


@media screen and (min-width: 2301px) and (max-width: 2400px) {
  #ChatBotContainer {
    width: 30%;
  }
}

@media screen and (min-width: 2401px) and (max-width: 4000px) {
  #ChatBotContainer {
    width: 30%;
  }
}



/* css for single form with send button icon start  */

.formSingleFields {
  display: flex;
  /* border: 1px solid red; */
  border-radius: 6px;
  background-color: white;
  justify-content: space-between;
  border:1px solid #7ead18;
}

.message p form button {
  /* color: #7ead18; */
  border-radius: 6px;
  outline: none;
  border: none;
}



.formInputError .formInputNamePhone,
.formInputError .formInputEmail {
  border: none;
  max-width: 170px;
}


/* css for single form with send button icon end */