#ChatBotContainer {
  position: fixed;
  bottom: 20px;
  right: 10px;
}

.container {
  background-color: #fff;
  border-radius: 25px;
  flex-direction: column;
  margin: 20px;
  display: flex;
  box-shadow: 0 4px 15px 5px #0000001a;
}

#output-container {
  justify-content: space-between;
  width: 100%;
  min-height: 60vh;
  margin-bottom: 10px;
  display: flex;
}

.dnone {
  display: none;
}

.messages-container {
  width: 55%;
}

.message-item {
  border: 1px solid #ddd;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 5px;
  padding: 8px;
  display: grid;
}

.success-status {
  background-color: var(--intercom-operator-message-bg);
  border-radius: 20px 20px 20px 0;
  grid-area: 1 / 1;
  width: fit-content;
  margin: 10px 0;
  padding: 15px;
  font-family: sans-serif;
  font-size: 14px;
  line-height: 1.6;
}

.message p {
  line-height: 1.8 !important;
}

.message {
  color: #fff;
  background-color: #182286;
  border-radius: 20px 20px 0;
  grid-area: 2 / 2;
  margin-left: 40px;
  margin-right: 5px;
  padding: 11px;
  font-family: sans-serif;
  font-size: 15px;
}

.hello-container div video, .hello-container video {
  border: 1px solid Zz !important;
  border: 1px solid Zz !important;
}

.hello-container div video {
  max-height: 60vh;
}

.hello-container {
  text-align: right;
  color: #007bff;
  justify-content: center;
  font-size: 18px;
  font-weight: bold;
  display: flex;
}

.input-container {
  border-top: 1px solid #d3d3d3;
  align-items: center;
  width: 100%;
  padding: 18px;
  display: flex;
}

input[type="text"] {
  border: 1px solid #ccc;
  border-radius: 5px 0 0 5px;
  flex: 1;
  padding: 8px;
  font-size: 16px;
}

button {
  color: #fff;
  cursor: pointer;
  background: #90b832;
  border: none;
  border-radius: 5px;
  width: 100px;
  padding: 8px 12px;
}

button:hover {
  background: #76a316;
}

button i {
  font-size: 16px;
}

.message-item {
  border: none;
  flex-direction: column;
  max-width: 100%;
  margin-bottom: 10px;
  display: flex;
}

.message {
  word-wrap: break-word;
  overflow-wrap: break-word;
  flex-direction: column;
  max-width: 100%;
  display: flex;
}

audio {
  width: 100%;
  margin-top: 5px;
}

.messages-container {
  max-width: 600px;
  max-height: 60vh;
  overflow: auto;
}

.input-container input {
  border: none;
  outline: none;
}

.input-container textarea {
  border: none;
  outline: none;
  width: 90%;
  height: 200px;
  font-family: sans-serif;
}

.input-container button {
  margin-left: 10px;
}

.recording-indicator {
  align-items: center;
  display: flex;
  position: relative;
}

.recording-line {
  background-color: red;
  width: 100%;
  height: 4px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.timer {
  margin-bottom: 15px;
  margin-left: 8px;
  font-weight: bold;
}

.recording-indicator button {
  color: #fff;
  cursor: pointer;
  z-index: 1;
  background-color: #f44336;
  border: none;
  margin-left: 10px;
  padding: 5px;
}

.recording-indicator button i {
  margin-right: 5px;
}

.chatHead {
  color: #fff;
  background-color: #7ead18;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 18px;
  display: flex;
}

.iconHeadChat img, .headMinClose img {
  border-radius: 100%;
  max-height: 35px;
  margin-right: 12px;
}

.headMinClose {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.iconHeadChat {
  align-items: center;
  display: flex;
}

.headTextClass .miniHead {
  margin-bottom: 6px;
  font-size: 14px;
}

.headTextClass {
  font-family: Arial, Helvetica, sans-serif;
}

.iconButton, .headMinClose img {
  cursor: pointer;
  height: 22px;
  margin-right: 10px;
}

.receiverIconText, .senderIconText {
  align-items: flex-end;
  display: flex;
}

.senderIconText {
  justify-content: flex-end;
}

.senderIconText img, .receiverIconText img {
  max-height: 35px;
  padding: 7px;
}

.helpfulText img {
  cursor: pointer;
}

.helpfulText .thumbsDownClass {
  margin-top: 7px;
}

.helpfulText {
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;
  font-family: sans-serif;
  display: flex;
}

.hoverZoom {
  margin-left: 5px;
  transition: transform .2s;
}

.hoverZoom:hover {
  transform: scale(1.5);
}

.container {
  transition: transform 1s, opacity 1s;
}

.container.slide-down {
  opacity: 1;
  transform: translateY(calc(100vh - 60px));
}

.chatHead {
  width: 100%;
  transition: transform 1s;
  bottom: 0;
}

.container.slide-down .chatHead {
  transform: translateY(0);
}

* {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.videochartvalue {
  border-radius: 11px 11px 0 0;
  justify-content: flex-end;
  transition: height .5s, width .5s;
  display: flex;
  bottom: -59px;
}

.newvideochartvalue {
  border-radius: 11px 11px 0 0;
  height: 0;
  transition: height 3s;
  position: fixed;
  bottom: 100px;
  right: 15px;
}

.receiver .senderIconText .message {
  color: #000;
  background-color: #f2f2f2;
  border-radius: 20px 20px 20px 0;
  margin: 0;
  padding: 15px;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.6 !important;
}

.receiver .senderIconText {
  flex-direction: row-reverse;
}

.chatBoxImageClass img {
  cursor: pointer;
  border-radius: 3px;
  max-height: 200px;
  position: absolute;
  bottom: 25px;
  right: 40px;
}

@keyframes Floating {
  0% {
    transform: translate(0);
  }

  65% {
    transform: translate(0, 50px);
  }

  100% {
    transform: translate(0);
  }
}

@media (width >= 769px) and (width <= 1220px) {
  .chatBoxImageClass img {
    max-height: 175px;
    bottom: 74px;
  }
}

@media (width <= 768px) {
  #output-container {
    flex-direction: column-reverse;
    align-items: center;
  }

  .hello-container {
    max-height: 36vh;
    padding: 5px;
  }

  .messages-container {
    width: 95%;
    min-height: 32vh;
  }

  .container {
    min-height: 66vh !important;
  }

  .headMinClose {
    width: 100%;
  }

  .chatBoxImageClass img {
    max-height: 175px;
    bottom: 74px;
  }
}

@media (width >= 446px) and (width <= 768px) {
  .hello-container div video, .hello-container video, .hello-container img {
    max-height: 25vh;
  }

  .chatBoxImageClass img {
    max-height: 175px;
    bottom: 74px;
  }
}

@media (width <= 400px) {
  .container {
    min-height: 71vh !important;
  }

  .chatBoxImageClass img {
    max-height: 175px;
    bottom: 74px;
  }
}

.chat-bubble {
  -moz-border-radius-bottomleft: 2px;
  -webkit-border-radius: 20px 20px 20px 2px;
  border-radius: 20px 20px 20px 2px;
  padding: 16px 28px;
  display: inline-block;
}

.typing {
  align-items: center;
  height: 17px;
  display: flex;
}

.typing .dot {
  vertical-align: middle;
  background-color: #6cad96;
  border-radius: 50%;
  width: 7px;
  height: 7px;
  margin-right: 4px;
  animation: 1.8s ease-in-out infinite mercuryTypingAnimation;
  display: inline-block;
}

.typing .dot:first-child {
  animation-delay: .2s;
}

.typing .dot:nth-child(2) {
  animation-delay: .3s;
}

.typing .dot:nth-child(3) {
  animation-delay: .4s;
}

.typing .dot:last-child {
  margin-right: 0;
}

@keyframes mercuryTypingAnimation {
  0% {
    background-color: #6cad96;
    transform: translateY(0);
  }

  28% {
    background-color: #9ecab9;
    transform: translateY(-7px);
  }

  44% {
    background-color: #b5d9cb;
    transform: translateY(0);
  }
}

.chat-bubble-audio {
  border-radius: 10px;
  max-width: 300px;
  padding: 5px;
  position: relative;
}

.chat-bubble {
  background-color: #f1f0f0;
  border-radius: 10px;
  max-width: 300px;
  padding: 5px;
  position: relative;
}

.typing {
  justify-content: center;
  align-items: center;
  display: flex;
}

.dot {
  background-color: gray;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  margin: 0 2px;
  animation: 1s ease-in-out infinite typing;
}

@keyframes typing {
  0%, 100% {
    opacity: .2;
    transform: translateY(0);
  }

  50% {
    opacity: 1;
    transform: translateY(-5px);
  }
}

.loaderVideo {
  border: 6px solid #f3f3f3;
  border-top-color: #7ead18;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: 2s linear infinite spin;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loaderVideoOutside {
  background-color: content-box;
  align-items: center;
  height: 50vh;
  display: flex;
}

.videochartvalue {
  bottom: -25px;
}

.slideChatBox {
  opacity: 0;
  animation: 2s ease-in-out forwards slide-up;
  position: relative;
}

@keyframes slide-up {
  0% {
    opacity: 1;
    transform: translateY(800px);
  }

  100% {
    opacity: 1;
    transform: translateY(100px);
  }
}

.languagesDiv .arrow .downArrowClass {
  height: 8px;
  margin-top: 4px;
  margin-right: 0;
}

.languagesDiv .languageDropdown .searchLanguageInput {
  border: none;
  outline: none;
}

.languagesDiv {
  cursor: pointer;
  border: 1px solid #fff;
  border-radius: 4px;
  justify-content: space-between;
  align-items: center;
  min-width: 110px;
  margin-top: 6px;
  margin-right: 10px;
  padding: 8px;
  font-family: Arial, Helvetica, sans-serif;
  display: flex;
  position: relative;
}

.arrow {
  margin-left: 10px;
  transition: transform .3s;
}

.arrow.open {
  transform: rotate(180deg);
}

.languageDropdown {
  color: #000;
  z-index: 1000;
  background-color: #fff;
  border: 1px solid #ccc;
  width: 100%;
  max-height: 150px;
  margin: 0;
  padding: 0;
  list-style-type: none;
  position: absolute;
  top: 100%;
  left: 0;
  overflow: hidden scroll;
}

.languageDropdown li {
  cursor: pointer;
  padding: 8px 12px;
}

.languageDropdown li:hover {
  background-color: #f0f0f0;
}

body, html {
  height: 100vh;
}

@media (width <= 576px) {
  .container {
    justify-content: space-between;
    margin: 0;
    display: flex;
  }

  .headMinClose {
    width: 100%;
  }

  .chatBoxImageClass img {
    max-height: 175px;
    bottom: 74px;
  }
}

@media (width <= 768px) {
  .container {
    justify-content: space-between;
    margin: 0;
    display: flex;
  }

  .headMinClose {
    width: 100%;
    margin-top: 5px;
  }

  .chatBoxImageClass img {
    max-height: 175px;
    bottom: 74px;
  }
}

@media (width <= 992px) {
  .container {
    border-radius: 0;
    justify-content: space-between;
    margin: 0;
    display: flex;
  }

  .chatHead {
    border-radius: 0;
  }

  .headMinClose {
    width: 100%;
  }

  .chatBoxImageClass img {
    max-height: 175px;
    bottom: 74px;
  }
}

@media (width <= 1200px) {
  .container {
    justify-content: space-between;
    margin: 0;
    display: flex;
  }

  .chatBoxImageClass img {
    max-height: 175px;
    bottom: 74px;
  }
}

.avatarNameImgClass {
  align-items: center;
  display: flex;
}

.speakerIconButton {
  justify-content: space-between;
  align-items: center;
  min-width: 65px;
  margin-left: 20px;
  display: flex;
}

.speakerIconButton img {
  margin-top: 5px;
  margin-right: 5px;
}

.languagesDivBoth {
  align-items: center;
  display: flex;
}

.recording-indicator img {
  height: 18px;
}

.errorPageMainClassOutside {
  justify-content: center;
  align-items: center;
  height: 100vh;
  display: flex;
}

.errorPageMainClass {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: sans-serif;
  display: flex;
}

.errorHead {
  color: red;
  font-size: 90px;
  font-weight: bold;
}

.errorOopsPara span {
  font-size: 50px;
}

.errorOopsPara {
  font-size: 30px;
}

.goBackButton {
  color: #fff;
  background-color: #acaced;
  border-radius: 8px;
  width: 150px;
  height: 35px;
  margin-top: 20px;
  padding: 9px;
}

.languageSearchDropdown {
  margin-top: 35px;
}

.chat-container {
  max-height: 500px;
  overflow-y: auto;
}

.response-options button {
  color: #7ead18;
  cursor: pointer;
  background-color: #0000;
  border: 1px solid #7ead18;
  margin: 5px;
}

.response-options button:hover {
  color: #fff;
  background-color: #7ead18;
  transition: all .3s;
}

.current-question {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
}

.chat-history div .botParaClass {
  background-color: #f2f2f2;
  border-radius: 5px;
  grid-area: 1 / 1;
  width: 65%;
  margin: 10px 0;
  padding: 15px;
  line-height: 1.3;
}

.botMsgClass img, .currentBotMsgClass img, .userMsgClass img {
  margin: 0 8px;
}

.currentBotMsgClass img, .botMsgClass img {
  max-height: 25px;
}

.botMsgClass {
  align-items: center;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  display: flex;
}

.userMsgClass .userParaClass {
  color: #fff;
  background-color: #182286;
  align-items: center;
  width: 60%;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  display: flex;
}

.userMsgClass {
  justify-content: end;
  align-items: center;
  width: 85%;
  margin-left: 25px;
  display: flex;
}

.userParaClass {
  border-radius: 5px;
  grid-area: 1 / 1;
  width: fit-content;
  padding: 13px;
}

.currentBotMsgClass {
  align-items: center;
  display: flex;
}

.currentBotParaClass {
  background-color: #f2f2f2;
  border-radius: 5px;
  grid-area: 1 / 1;
  width: 65%;
  margin: 10px 0;
  padding: 13px;
  line-height: 1.3;
}

.chatMainClass {
  width: 80%;
  height: 60vh;
  overflow: scroll;
}

.staticVideoClass {
  text-align: center;
}

.contactFormError {
  color: red;
  margin: 0 0 3px 8px;
  font-size: 13px;
}

.formInputError {
  flex-direction: column;
  display: flex;
}

.formInputNamePhone {
  border: 1px solid #7ead18;
  border-radius: 3px;
  outline: none;
  max-width: 220px;
  margin-top: 4px;
}

.formInputError .formInputEmail {
  border: 1px solid #7ead18;
  border-radius: 6px;
  outline: none;
  max-width: 220px;
  margin-top: 4px;
  margin-bottom: 5px;
  padding: 9px;
}

.message p form button {
  color: #7ead18;
  background-color: #fff;
  border: 1px solid #7ead18;
  border-radius: 6px;
  outline: none;
}

@media (width <= 768px) {
  .chatMainClass {
    height: 53vh;
  }

  .hello-container {
    width: 40%;
  }
}

.inputErrorClass {
  color: red;
  margin-left: 7px;
}

.chatBoxImageClass img {
  border-radius: 100%;
  max-height: 175px;
  bottom: 74px;
}

@media only screen and (width: 275px) and (height: 374px) and (-webkit-device-pixel-ratio: 2) {
  #ChatBotContainer {
    width: 90%;
    height: 90vh;
  }

  #output-container {
    min-height: 70vh;
    margin-bottom: 3px;
  }

  .messages-container {
    max-height: 45vh;
  }

  .chatBoxImageClass img {
    max-height: 175px;
    bottom: 74px;
  }
}

@media only screen and (width: 375px) and (height: 667px) and (-webkit-device-pixel-ratio: 2) {
  #ChatBotContainer {
    width: 90%;
    height: 90vh;
  }

  #output-container {
    min-height: 70vh;
    margin-bottom: 3px;
  }

  .messages-container {
    max-height: 45vh;
  }
}

@media screen and (width >= 200px) and (width <= 500px) {
  .headMinClose {
    width: 100%;
  }
}

@media screen and (width >= 200px) and (width <= 375px) {
  .headMinClose {
    width: 100%;
  }
}

@media screen and (width >= 200px) and (width <= 600px) {
  #ChatBotContainer {
    width: 100%;
    height: 94vh;
    bottom: 0;
    right: 0;
  }

  .chatMainClass {
    width: 98%;
  }

  .input-container input[type="text"] {
    padding: 16px;
  }

  .userMsgClass {
    width: 92%;
  }
}

@media screen and (width >= 301px) and (width <= 600px) {
  .hello-container div video {
    max-height: 26vh;
  }

  .chatHead {
    padding: 25px 8px;
  }

  .languagesDiv {
    min-width: 90px;
    font-size: .75rem;
  }

  .response-options {
    font-size: .75rem;
  }

  #output-container {
    min-height: 70vh;
    margin-bottom: 3px;
  }

  .messages-container {
    max-height: 40vh;
  }

  .speakerIconButton {
    min-width: 10px;
    margin-left: 4px;
  }

  .input-container {
    padding: initial;
  }
}

@media screen and (width >= 601px) and (width <= 767px) {
  #ChatBotContainer {
    width: 100%;
    height: 90vh;
    bottom: 0;
    right: 0;
  }

  .chatMainClass {
    width: 95%;
  }

  .userMsgClass {
    width: 93%;
  }

  .headMinClose {
    width: initial;
  }

  #output-container {
    min-height: 70vh;
    margin-bottom: 3px;
  }

  .messages-container {
    max-height: 45vh;
  }
}

@media screen and (width >= 768px) and (width <= 900px) {
  #ChatBotContainer {
    width: 75%;
  }

  .headMinClose {
    width: initial;
  }

  #output-container {
    max-height: 70vh;
  }
}

@media screen and (width >= 901px) and (width <= 1000px) {
  #ChatBotContainer {
    width: 80%;
  }

  .hello-container div video, .hello-container video, .hello-container img {
    max-height: 61vh;
  }
}

@media screen and (width >= 1001px) and (width <= 1100px) {
  #ChatBotContainer {
    width: 80%;
  }

  .hello-container div video, .hello-container video, .hello-container img {
    max-height: 61vh;
  }
}

@media screen and (width >= 1101px) and (width <= 1200px) {
  #ChatBotContainer {
    width: 70%;
  }

  .hello-container div video, .hello-container video, .hello-container img {
    max-height: 61vh;
  }
}

@media screen and (width >= 1201px) and (width <= 1300px) {
  #ChatBotContainer {
    width: 70%;
  }

  .hello-container div video, .hello-container video, .hello-container img {
    max-height: 61vh;
  }
}

@media screen and (width >= 1301px) and (width <= 1400px) {
  #ChatBotContainer {
    width: 47%;
  }

  .hello-container div video, .hello-container video, .hello-container img {
    max-height: 61vh;
  }
}

@media screen and (width >= 1401px) and (width <= 1500px) {
  #ChatBotContainer {
    width: 50%;
  }

  .hello-container div video, .hello-container video, .hello-container img {
    max-height: 61vh;
  }
}

@media screen and (width >= 1501px) and (width <= 1600px) {
  #ChatBotContainer {
    width: 50%;
  }

  .hello-container div video, .hello-container video, .hello-container img {
    max-height: 61vh;
  }
}

@media screen and (width >= 1601px) and (width <= 1700px) {
  #ChatBotContainer {
    width: 45%;
  }

  .hello-container div video, .hello-container video, .hello-container img {
    max-height: 61vh;
  }
}

@media screen and (width >= 1701px) and (width <= 1800px) {
  #ChatBotContainer {
    width: 45%;
  }

  .hello-container div video, .hello-container video, .hello-container img {
    max-height: 61vh;
  }
}

@media screen and (width >= 1801px) and (width <= 1900px) {
  #ChatBotContainer {
    width: 40%;
  }

  .hello-container div video, .hello-container video, .hello-container img {
    max-height: 61vh;
  }
}

@media screen and (width >= 1901px) and (width <= 2000px) {
  #ChatBotContainer {
    width: 40%;
  }

  .hello-container div video, .hello-container video, .hello-container img {
    max-height: 61vh;
  }
}

@media screen and (width >= 2001px) and (width <= 2100px) {
  #ChatBotContainer {
    width: 40%;
  }
}

@media screen and (width >= 2101px) and (width <= 2200px) {
  #ChatBotContainer {
    width: 35%;
  }
}

@media screen and (width >= 2201px) and (width <= 2300px) {
  #ChatBotContainer {
    width: 35%;
  }
}

@media screen and (width >= 2301px) and (width <= 2400px) {
  #ChatBotContainer {
    width: 30%;
  }
}

@media screen and (width >= 2401px) and (width <= 4000px) {
  #ChatBotContainer {
    width: 30%;
  }
}

.formSingleFields {
  background-color: #fff;
  border: 1px solid #7ead18;
  border-radius: 6px;
  justify-content: space-between;
  display: flex;
}

.message p form button {
  border: none;
  border-radius: 6px;
  outline: none;
}

.formInputError .formInputNamePhone, .formInputError .formInputEmail {
  border: none;
  max-width: 170px;
}
/*# sourceMappingURL=index.ce19b718.css.map */
